import { getDeviceSize } from '~/utils/device'

export default {
  props: {
    button: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDesktop: null,
      showFixedCta: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.showHideCta)
    })
  },

  methods: {
    checkDeviceSize() {
      const device = getDeviceSize()
      if (/desktop(?:\-(?:xl|[lm]))?/.test(device)) {
        this.isDesktop = true
      } else {
        this.isDesktop = false
      }
    },

    showHideCta() {
      this.checkDeviceSize()
      let y = window.scrollY
      if (y >= 500 && !this.isDesktop) {
        this.showFixedCta = true
      } else {
        this.showFixedCta = false
      }
    },
  },
}
